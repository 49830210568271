.custom_radio_btn{
    width: 16px;
    height: 16px;
    border:1px solid #222;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.custom_radio_btn .checkmark{
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    background-color: #222;
    display:inline-block;
    opacity:0;
    transition: opacity .25s ease;
}

.custom_radio_btn input{
    display: none;
}

.custom_radio_btn input:checked + .checkmark{
    opacity: 1;
    width: 100%;
    height: 100%;
}
@tailwind base;
@tailwind components;
@tailwind utilities;

/* @import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities"; */

@import url('https://fonts.googleapis.com/css2?family=Blinker:wght@300&family=Quicksand:wght@300&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=Manrope:wght@300&display=swap');
@font-face {font-family: "Android 101"; src: url("//db.onlinewebfonts.com/t/08e4e294dbfff0890489fcfd6b2fafc0.eot"); src: url("//db.onlinewebfonts.com/t/08e4e294dbfff0890489fcfd6b2fafc0.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/08e4e294dbfff0890489fcfd6b2fafc0.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/08e4e294dbfff0890489fcfd6b2fafc0.woff") format("woff"), url("//db.onlinewebfonts.com/t/08e4e294dbfff0890489fcfd6b2fafc0.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/08e4e294dbfff0890489fcfd6b2fafc0.svg#Android 101") format("svg"); } */

*{
  margin:0px;
  padding:0px;
  font-family: 'Blinker', sans-serif;
  font-weight: 400;
  font-style: normal;
  box-sizing: border-box;
}

.custom_container::-webkit-scrollbar{
  display: none;
}